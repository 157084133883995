.amplify-tabs {
  display: none;
}

/* for country flag bug */
.fi:before {
  color: rgba(0 0 0 / 0%)!important;
}

[data-amplify-authenticator][data-variation=modal] {
  background-color: #047d95;
}

[data-amplify-authenticator][data-variation="modal"]:before {
  background-image: none;
  opacity: 1;
}

[data-amplify-authenticator][data-variation="modal"] [data-amplify-router-content] {
  max-height: max-content;
  overflow-y: hidden;
}

/* for Forecast table cell */
.hide-spin::-webkit-inner-spin-button,
.hide-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance:textfield;
}

/* for stockmovement page  */
.MuiDataGrid-panelHeader~.MuiDataGrid-panelFooter{display: none !important;}
